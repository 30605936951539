import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import App from './App';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { version } from '../package.json';
import './main.scss';

const FakeEvents = lazy(() => import('./components/FakeEvents'));
const isDevelopment = import.meta.env.MODE === 'development';
const root = document.getElementById('course-study-tool')!;

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta name="version" content={version} />
    </Helmet>
    <Suspense fallback={<LoadingPage />}>
      {isDevelopment && <FakeEvents />}
      <App />
    </Suspense>
  </React.StrictMode>,
  root
);
